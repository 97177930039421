import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "creditLimitDecreasedReasons",
    "creditLimitDecreasedReasonsSection",

    "creditPolicyCancellationReasons",
    "creditPolicyCancellationReasonsSection",

    "creditPolicyRejectionReasons",
    "creditPolicyRejectionReasonsSection",
  ];

  connect () {
    if (this.hasCreditLimitDecreasedReasonsTarget) {
      this.appendReasonsOnConnect(
        this.creditLimitDecreasedReasonsTarget,
        this.creditLimitDecreasedReasonsSectionTarget,
      );
    }

    if (this.hasCreditPolicyCancellationReasonsTarget) {
      this.appendReasonsOnConnect(
        this.creditPolicyCancellationReasonsTarget,
        this.creditPolicyCancellationReasonsSectionTarget,
      );
    }

    if (this.hasCreditPolicyRejectionReasonsTarget) {
      this.appendReasonsOnConnect(
        this.creditPolicyRejectionReasonsTarget,
        this.creditPolicyRejectionReasonsSectionTarget,
      );
    }
  }

  toggleCreditLimitDecreaseReason (e) {
    this.appendOrRemoveReasonsOnToggle(
      e,
      this.creditLimitDecreasedReasonsSectionTarget,
    );
  }

  toggleCreditPolicyCancellationReason (e) {
    this.appendOrRemoveReasonsOnToggle(
      e,
      this.creditPolicyCancellationReasonsSectionTarget,
    );
  }

  toggleCreditPolicyRejectionReason (e) {
    this.appendOrRemoveReasonsOnToggle(
      e,
      this.creditPolicyRejectionReasonsSectionTarget,
    );
  }

  appendOrRemoveReasonsOnToggle (e, sectionElementTarget) {
    const uniqueSpanId =
      e.target.value +
      sectionElementTarget.dataset.creditPolicyAdminManagementTarget;
    if (e.target.checked) {
      const span = document.createElement("span");
      span.innerText = `- ${e.target.nextSibling.data}`;
      span.id = uniqueSpanId;
      sectionElementTarget.appendChild(span);
    } else if (!e.target.checked) {
      const el = document.getElementById(uniqueSpanId);
      el.remove();
    }
  }

  appendReasonsOnConnect (target, sectionElementTarget) {
    Array.from(target.children).forEach(function (element) {
      if (element.firstChild.checked) {
        const span = document.createElement("span");
        span.innerText = `- ${element.firstChild.nextSibling.data}`;
        span.id = element.firstChild.value;
        sectionElementTarget.appendChild(span);
      }
    });
  }
}
